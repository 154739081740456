<script setup>

// setInterval(async () => {
//     const { data, error } = await useAPI('config')
// }, 1000 * 30)

const openSidebar = ref()

</script>
<template>
<v-app>
    <CoreSideBar v-model="openSidebar"/>
    <CoreNavBar @toggleSidebar="openSidebar = !openSidebar"/>
    <v-main class="bg-blue-grey-lighten-5">
            <slot />
    </v-main>

    <CoreDialog />
    <CoreNotification />
    <v-footer app height="40"><v-spacer />©  {{ (new Date()).getFullYear() }} Trialsh, made with ❤️ for a better world</v-footer>
</v-app>
</template>